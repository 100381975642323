<!--
 * @Author: mulingyuer
 * @Date: 2021-08-25 10:30:03
 * @LastEditTime: 2021-09-03 15:31:38
 * @LastEditors: mulingyuer
 * @Description: 课程管理筛选
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-manage\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" :start-time.sync="form.create_start_time"
          start-prop="create_start_time" :end-time.sync="form.create_end_time" end-prop="create_end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="课程名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入课程名称"></el-input>
        </el-form-item>
        <el-form-item v-if="!isGov" label="课程分类：" prop="category_id">
<!--          <el-cascader  v-model="form.category_id"-->
<!--                        :options="categoryArr"-->
<!--                        :props="{-->
<!--                          checkStrictly: true,-->
<!--                          emitPath: false,-->
<!--                          label: 'name',-->
<!--                          value: 'id',-->
<!--                        }"-->
<!--                        show-all-levels-->
<!--          />-->
          <el-select v-model="form.category_id">
            <el-option v-for="ca in categoryArr" :key="ca.id" :label="ca.name" :value="ca.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-else label="课程分类：" prop="category_name">
          <el-select v-model="form.category_name">
            <el-option v-for="ca in categoryArr" :key="ca.value" :label="ca.name" :value="ca.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建人：" prop="user_keywprd">
          <el-input v-model="form.user_keywprd" placeholder="请输入创建人"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="课程类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择课程类型">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示范围：" prop="show_type">
          <el-select v-model="form.show_type" placeholder="请选择显示范围">
            <el-option v-for="op in showTypeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { category } from "../../../api/course-manage/index";
import { getGovCourseCategories } from "@/modules/gov/api/course-library";
export default {
  name: 'course-list-filter',
  props: {
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    // exportData: {
    //   type: Object,
    //   required: true,
    // },
    filterForm: Object,
    isGov: Boolean,
  },
  data() {
    return {
      form: {
        create_start_time: -1, //创建开始时间
        create_end_time: -1, //创建结束时间
        keyword: "", // 课程名称搜索
        category_id: "", // 课程分类
        user_keyword: "", // 创建人搜索
        type: -1, //课程类型
        show_type: -1, //显示返回
      },
      rules: {},
      //课程分类数据
      categoryArr: [{ name: "全部", id: "" }],
      //课程类型选项数据
      typeArr: [
        { name: "全部", id: -1 },
        { name: "独立课程", id: 0 },
        { name: "章节课程", id: 1 },
      ],
      //显示范围选项数据
      showTypeArr: [
        { name: "全部", id: -1 },
        { name: "全部学员", id: 1 },
        { name: "部分学员", id: 2 },
        { name: "不显示", id: 0 },
      ],
    };
  },
  methods: {
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      return this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign(this.filterForm && this.filterForm.project_id !== undefined ? {
        ...this.filterForm
      } :{}, this.form));
    },
    //导出事件
    onExport() {},
    //获取分类选项数据
    getCategoryArr() {
      if (this.isGov) {
        getGovCourseCategories().then(res => {
          const { data } = res;
          this.categoryArr.push(...data);
        })
        return
      }
      category()
        .then((res) => {
          const { data } = res;
          this.categoryArr.push(...data);
        })
        .catch(() => {});
    },
  },
  created() {
    this.getCategoryArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
